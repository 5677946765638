<template>
  <div class="full">
    <section class="p_f od_top">
      <div class="order_nav d_f ali_c">
        <img src="../../views/hotel/img/order_back.png" alt="" class="cur_p" @click="goBackList">
        <span>订单详情</span>
      </div>
      <div class="top_content">
        <div class="d_f ali_c">
          <template v-if="info[0].orderStatus == 2 && info[0].closeType == 2">
            <div>
              <h3 class="title">订单已取消</h3>
              <h5 class="sub">预定失败，已自动退还支付订金。</h5>
            </div>
          </template>

          <template v-else>
            <template v-if="info[0].refundStatus === null || info[0].refundStatus === 0">
              <!--待付款-->
              <template v-if="info[0].orderStatus == 1 || info[0].orderStatus == 0">
                <div>
                  <h3 class="title">待付款</h3>
                  <h5 class="sub">超时后，订单将自动关闭。</h5>
                </div>
                <div class="flex_1 t_r cut_down" v-if="cutDownTime>0">剩余时间：<van-count-down @finish="reloadInfo" class="d_i" :time="cutDownTime"/></div>
              </template>
              <!--已取消-->
              <template v-if="info[0].orderStatus == 2">
                <div>
                  <h3 class="title">订单已取消</h3>
                  <h5 class="sub" v-if="info[0].closeType == 'DB'">订单已退款</h5>
                  <h5 class="sub" v-else>欢迎再次选购</h5>
                </div>
              </template>
              <!--已消费-->
              <template v-if="info[0].orderStatus == 7">
                <div>
                  <h3 class="title">已消费</h3>
                  <h5 class="sub">欢迎再次选购</h5>
                </div>
              </template>
              <!--待确认-->
              <template v-if="info[0].orderStatus == 4">
                <div>
                  <h3 class="title">已付款</h3>
                  <h5 class="sub">待工作人员为您确认订单，过程非常短暂，请耐心等候</h5>
                </div>
              </template>
              <!--预订成功-->
              <template v-if="info[0].orderStatus == 5">
                <div>
                  <h3 class="title">待使用</h3>
                  <h5 class="sub">请携带出行人身份证进行使用。</h5>
                </div>
              </template>
            </template>

            <!--退款-->
            <template v-else>
              <!--退款审核中-->
              <template v-if="info[0].orderRefundStatus == 1">
                <div>
                  <h3 class="title">退款审核中</h3>
                  <h5 class="sub">退款申请已受理，正在审核中，请耐心等待。</h5>
                </div>
              </template>
              <!--退款失败-->
              <template v-if="info[0].orderRefundStatus == 5 || info[0].orderRefundStatus == 8">
                <div>
                  <h3 class="title">退款失败</h3>
                  <h5 class="sub">商家拒绝了您的退款申请。</h5>
                </div>
              </template>
              <!--退款成功-->
              <template v-if="info[0].orderRefundStatus == 6">
                <div>
                  <h3 class="title d_f ali_c">
                    <span>商家已退款</span>
                    <div class="flex_1 t_r d_f ali_c hod_right">
                      <div class="cur_p flex_1"  @click="goToED('/refundProgress',{orderNumber:orderNumber,hotelName:info[0].commoditytName})">
                        <span>退款进度</span>
                        <img src="../hotel/img/hod_right.png" alt="">
                      </div>
                    </div>
                  </h3>
                  <h5 class="sub">退款申请已审核通过，商家已退款预计1-2个工作日到账</h5>
                </div>
              </template>
              <div v-if="info[0].orderRefundStatus != 6" class="flex_1 t_r d_f ali_c hod_right">
                <div class="cur_p flex_1"  @click="goToED('/refundProgress',{orderNumber:orderNumber,hotelName:info[0].commoditytName})">
                  <span>退款进度</span>
                  <img src="../hotel/img/hod_right.png" alt="">
                </div>
              </div>
            </template>
          </template>

        </div>
        <!--待付款-->
        <div class="white_card" v-if="info[0].orderStatus == 1 || info[0].orderStatus == 0">
          <div class="head">
            <span>取消规则</span>
            <!--<span class="black">{{info[0].beginDate.split('-')[1]+'月'+info[0].beginDate.split('-')[2]+'日'}}18:00前可免费取消</span>-->
            <span class="black">{{info[0].refundNotice || '--'}}</span>
          </div>
          <div class="btn_out d_f ali_c j_s t_a">
            <div class="btn" @click="cancelOrder">取消订单</div>
            <div class="btn ac" @click="goToPay">去支付￥{{(info[0].sellPrice - totalIntegralMoney) / 100}}</div>
          </div>
        </div>
        <!--已取消,待确认-->
        <div class="btn ac full t_a mt_15 cur_p" @click="$router.push('/lineJp')"  v-if="(info[0].orderStatus == 7 && info[0].isPreview == 1) || (info[0].orderRefundStatus == 6 && info[0].refundStatus !== null) || info[0].orderStatus == 2 || (info[0].orderStatus == 4 && (info[0].refundStatus === null || info[0].refundStatus === 0))">再次预定</div>

        <!--预订成功-->
        <div class="btn_out d_f ali_c j_s t_a two mt_15"  v-if="(info[0].orderStatus == 5 &&  (info[0].refundStatus === null || info[0].refundStatus === 0))">
          <div class="btn ac cur_p" @click="showTel = true">申请退款</div>
          <div class="btn ac cur_p" @click="$router.push('/lineJp')">再次预订</div>
        </div>
        <!--已消费-->
        <div class="btn_out d_f ali_c j_s t_a mt_15" v-if="info[0].orderStatus == 7 && info[0].isPreview == 0 && info[0].refundStatus == null">
          <div class="btn ac cur_p" @click="$router.push('/lineJp')">再次预订</div>
        </div>
        <!--退款-->
        <template v-if="info[0].refundStatus !== null">
          <div class="btn ac full t_a mt_15 cur_p" v-if="info[0].orderRefundStatus == 1" @click="recallOrder">撤销申请</div>
          <div class="refuse mt_15" v-if="info[0].orderRefundStatus == 5 || info[0].orderRefundStatus == 8">
            拒绝理由：{{info[0].missCause}}
          </div>
        </template>
      </div>
    </section>
    <section class="h_content" :class="info[0].orderStatus == 0 || info[0].orderStatus == 1 ? 'tall' : ''">
      <!--费用明细那一块-->
      <div class="bc_w price_out">
        <div class="d_f ali_c">
          <span class="title" v-if="info[0].orderStatus == 0
          || info[0].orderStatus == 1
          || info[0].orderRefundStatus == 8
          || (info[0].refundStatus !== null && info[0].orderRefundStatus == 1)
          || (info[0].orderStatus == 2 && info[0].closeType == 1)
           || (info[0].orderStatus == 7 && (info[0].refundStatus === null || info[0].refundStatus === 0))
          || (info[0].orderStatus == 2 && info[0].closeType == 3)">在线支付</span>
          <span class="title" v-if="info[0].orderStatus == 4 || (info[0].orderStatus == 5 && (info[0].refundStatus === null || info[0].refundStatus === 0))">已支付</span>
          <span class="title" v-if="(info[0].orderStatus == 2 && info[0].closeType == 2 && (info[0].refundStatus === null || info[0].refundStatus === 0)) || (info[0].refundStatus !== null && info[0].orderRefundStatus == 6)">已退款</span>
        </div>
        <div class="back_tip" v-if="(info[0].refundStatus !== null && info[0].orderRefundStatus == 6)">订单已取消，无需扣款费用</div>
        <div class="order_num d_f ali_c">
          <span class="title">订单号</span>
          <span class="num">{{orderNumber}}</span>
          <div class="cur_p t_a copy_btn" @click="doCopy" :data-clipboard-text="info[0].orderNumber">复制</div>
        </div>
        <div class="td_rule d_f" v-if="(info[0].refundStatus !== null && info[0].orderRefundStatus == 6)">
          <div class="td_title">退订规则：</div>
          <div class="flex_1">{{info[0].refundNotice || '--'}}</div>
        </div>
      </div>
      <div class="bc_w d_out">
        <!--酒店信息done-->
        <div class="title d_f ali_c">
          <span class="flex_1 text_over">套餐信息</span>
          <div class="d_f ali_c price_detail_green cur_p">
            <span>小计：</span>
            <span class="num">￥{{(info[0].sellPrice - totalIntegralMoney) / 100}}</span>
          </div>
        </div>
        <div class="sub_title">{{info[0].commoditytName}}</div>

        <dl>
          <dt>-包含-</dt>
          <dd v-for="(item,index) in comidityInfo.commodityDetails" :key="index">● {{item.name}}</dd>
        </dl>


        <div class="taocan">
          <h6>- 套餐说明 -</h6>
          <ul>
            <li>
              <span></span>
              <p>{{comidityInfo.remark}}</p>
            </li>
          </ul>
        </div>
      </div>
      <div class="bc_w">

        <div class="content">
          <h3>套餐详情</h3>
          <div v-html="comidityInfo.depiction"></div>
        </div>
      </div>
    </section>

    <!-- 客服咨询 -->
    <van-popup v-model:show="showTel" closeable round >
      <div class="telPop">
        <span class="kefu">申请退款</span>
        <p>如需申请退款，可拨打如下客服号码</p>
        <a :href="'tel:' + hotelInfo.telephone || '4008196333'" rel="external nofollow" >{{hotelInfo.telephone || '4008196333'}}</a>
      </div>
    </van-popup>
  </div>
</template>

<script>
  // import Steps from "../../hotel/component/steps";
  import { Dialog,Toast, CountDown, Popup } from 'vant';
  import apis from '../../views/hotel/utils/apis'

  export default {
    name: "comOrderDetail",
    components: {
      'van-count-down':CountDown,
      'van-dialog':Dialog,
      "van-popup":Popup
    },
    data() {
      return {
        showTel:false,
        registSpets:0,
        SetData : ["用户信息", "魔童哪吒", "齐天大圣", "赤脚大仙", "我爱中国"],
        cutDownTime:0,
        activeStep:2,
        isShowRoom:false,
        isShowRR:false,
        isShowComment:false,
        orderNumber:this.$route.query.orderNumber,
        info:[{beginDate:'',endDate:''}],
        sellerId:null,
        hotelInfo:{},
        roomPopInfo:{},
        priceArr:[],
        totalPrice:0,
        priceQuery:{},
        priceQueryLink:{},
        totalIntegralMoney: 0, // 积分抵扣金额
        comidityInfo:{},
        commodityId:'',
      }
    },
    mounted(){
      this.findHotelOrderDetail();
    },
    created(){

    },
    methods: {
      recallOrder(){
        let _t = this;
        Dialog.confirm({
          cancelButtonText: '我再想想',
          message: '确定撤销申请吗？',
        })
          .then(() => {
            _t.$post({
              url: apis.revokeRefundApply,
              params:{
                token:_t.$global.token,
                orderNumber: _t.orderNumber,
              }
            }).then(res => {
              Toast('操作成功');
              _t.reloadList();
            }).catch(err =>{
              Toast(err.message);
            })
          })
          .catch(() => {
            // on cancel
          });
      },
      findCommodity(){
        let me = this;
        me.$get({
          url: apis.findCommodity,
          params:{
            commodityId: me.commodityId,
            token: me.$global.token
          }
        }).then(rsp => {
          this.comidityInfo = rsp;
        })
      },
      reloadInfo(){
        this.isShowRR = false;
        this.isShowComment = false;
        this.findHotelOrderDetail();
      },
      //跳转酒店列表
      goBackList(){
        let _t = this;
        _t.$router.go(-1)

      },
      //去支付
      goToPay(){
        let _t = this;
        _t.$router.push({
          path:'/payWay',
          query:{
            price:_t.info[0].sellPrice  ? (_t.info[0].sellPrice  - _t.totalIntegralMoney) : 0,
            buyNum:_t.info.length,
            code:_t.info[0].orderNumber,
            back:'tc'
          }
        })
      },
      //申请退款
      applyRefund(){
        Dialog.confirm({
          cancelButtonText: '我再想想',
          message: '确定申请退款吗？',
        })
          .then(() => {
            this.isShowRR = true;
            // on confirm
          })
          .catch(() => {
            // on cancel
          });
      },
      // 费用明细、退款进度跳转
      goToED(ph,query){
        this.$router.push({
          path:ph,
          query:query
        })
      },
      //取消订单done
      cancelOrder(){
        let me = this;
        Dialog.confirm({
          title: '提示',
          message: '确认取消订单？',
        }).then(() => {
          me.$get({
            url:apis.cancelOrder,
            params: {
              orderId: me.info[0].orderId,
              token:me.$global.token,
            }
          }).then(res => {
            Toast('取消成功');
            setTimeout(function () {
              me.findHotelOrderDetail()
            },1000)
          });
        })

      },
      //复制订单号done
      doCopy(){
        let _t = this;
        let clipboard = new _t.$Clipboard(".copy_btn"); //在main.js中引用
        clipboard.on("success", e => {
          // 释放内存
          Toast('复制成功')
          clipboard.destroy();
        });
        clipboard.on("error", e => {
          // 不支持复制
          Toast({
            message: "该浏览器不支持自动复制",
            type: "warning"
          });
          // 释放内存
          clipboard.destroy();
        });
      },
      //订单详情done
      findHotelOrderDetail(){
        let _t = this;
        _t.$get({
          url:apis.detail,
          params: {
            orderNumber: _t.orderNumber,
            token:_t.$global.token,
          }
        }).then(res => {
          _t.info = JSON.parse(JSON.stringify(res));
          _t.sellerId = res[0].sellerId;
          _t.commodityId = res[0].commodityId;
          _t.findCommodity();
          _t.cutDownTime = new Date(_t.$renderTime(res[0].creatorTime)).getTime()+15 * 60 * 1000-new Date().getTime();
          _t.findInfo();
          let totalIntegralMoney = 0
          _t.info.map( item => {
            totalIntegralMoney += item.totalDiscountAmount
          })
          _t.totalIntegralMoney = totalIntegralMoney
        });
      },
      //获取商家详情done
      findInfo(){
        let _t = this;
        _t.$get({
          url:apis.findInfo,
          params: {
            orderNumber: _t.orderNumber,
            sellerId:_t.sellerId || 105262,
          }
        }).then(res => {
          _t.hotelInfo = res;
        });
      },
    }
  }
</script>

<style scoped lang="scss">

  .telPop{
    width: 350px;
    height: 180px;
    p{
      font-size: 15px;
      font-weight: 500;
      color: #333333;
      text-align: center;
      margin-top: 26px;
    }
    a{
      font-size: 24px;
      display: block;
      text-align: center;
      margin-top: 27px;
      font-weight: bold;
      color: #333333;

    }
    .kefu{
      display: block;
      text-align: center;
      margin: 16px auto 0;
      width: 108px;
      height: 24px;
      color: #FFFFFF;
      line-height: 24px;
      font-size: 12px;
      background: #F96E6E;
      border-radius: 12px;
    }
  }
  .content {
    padding-top: 10px;
    >div{
      width: 100%;
      margin: 0 auto;
      ::v-deep img{
        max-width: 100%;
      }
    }
    h3 {
      width: 100%;
      margin: 0 auto;
      text-align: center;
      background: url("./img/line.png") no-repeat center;
      background-size: 100% 1px;
      font-size: 18px;
      margin-bottom: 10px;
    }
  }
  .taocan {
    width: 100%;
    background: #fffcf3;
    border: 1px solid #f96937;
    border-radius: 10px;
    margin: 11px auto 17px;
    ul {
      margin-top: 17px;
      li {
        margin-bottom: 15px;
        span {
          display: inline-block;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background: #0d0d0d;
          vertical-align: top;
          margin-left: 10px;
          margin-top: 5px;
        }
        p {
          display: inline-block;
          width: 300px;
          font-size: 14px;
          color: #333333;
          line-height: 20px;
          margin-left: 6px;
        }
      }
    }
    h6 {
      height: 14px;
      font-size: 14px;
      font-weight: bold;
      font-style: italic;
      color: #f96937;
      line-height: 35px;
      text-align: center;
    }
  }
  dl{
    border-top: 1PX solid #D6D6D6;
    margin-top: 10px;
    padding-top: 10px;
    dt{
      font-size: 12px;
      font-style: italic;
      color: #F96937;
    }
    dd{
      font-size: 12px;
      margin-top: 7px;
      font-weight: 500;
      color: #333333;
    }
  }
  .bottom_tip{
    .tip_grey{
      font-size:12px;
      font-weight:500;
      color:rgba(142,142,142,1);
      line-height:17px;
      >div{
        margin-bottom: 9px;
      }
    }
    .tip_title{
      font-size:13px;
      font-weight:500;
      color:rgba(62,62,62,1);
      line-height:17px;
      margin-bottom: 12px;
    }
    img{
      width: 13px;
      height: 13px;
      margin-right: 7px;
      position: relative;
      top: 1px;
    }
    background-color: #f0f0f0;
    padding: 18px 22px;
    font-size:12px;
    font-weight:500;
    color:rgba(62,62,62,1);
  }
  .hod_in_out{
    .hodi_title{
      margin: 30px 0 13px 0;
      font-size:13px;
    }
    >div{
      margin-bottom: 15px;
    }
    font-size:12px;
    font-weight:500;
    color:rgba(62,62,62,1);
    margin-bottom: 25px;
  }
  .hod_th{
    font-size:12px;
    font-weight:500;
    color:rgba(62,62,62,1);
    .th_title{
      width: 85px;
      color:rgba(142,142,142,1);
    }
    padding: 17px 0;
  }
  .hod_fk{
    >div{
      margin-top: 19px;
    }
    padding-bottom: 19px;
    .name{
      font-size:12px;
      font-weight:500;
      color:rgba(62,62,62,1);
    }
    .title{
      div{
        width: 12px;
        height: 12px;
        border-radius: 50%;
        border: 1px solid #8E8E8E;
        color: #8E8E8E;
        text-align: center;
        font-size: 10px;
        line-height: 10px;
        box-sizing: border-box;
        margin-left: 6px;
      }
      margin-bottom: 0!important;
      width: 85px;
      font-size:12px;
      font-weight:500;
      color:rgba(142,142,142,1);
    }
  }
  .hod_fs{
    .time_out{
      font-size:12px;
      font-weight:500;
      color:rgba(62,62,62,1);
      .time_date{
        width: 60px;
      }
      .time_title{
        width:85px;
        color:rgba(142,142,142,1);
      }
      margin-bottom: 10px;
    }
    .title{
      font-size:13px;
      font-weight:500;
      color:rgba(62,62,62,1);
      margin-bottom: 18px;
    }
    padding-bottom: 10px;
  }
  .bb{
    border-bottom: 1px solid #D0D0D0;
  }
  .d_out{
    margin-bottom: 10px;
    padding-bottom: 1px!important;
    .map_out{
      .cut{
        margin-left: 43px;
        color: #D0D0D0;
        font-size: 10px;
      }
      .map,.contact{
        font-size:13px;
        font-weight:500;
        color:rgba(62,62,62,1);
        img{
          width: 15px;
          margin: 0 9px 0 40px;
        }
      }
      padding-bottom: 9px;
      margin-bottom: 18px;
    }
    .sub_title{
      font-size:12px;
      font-weight:500;
      color:rgba(62,62,62,1);
    }
    font-size:14px;
    font-weight:bold;
    color:rgba(62,62,62,1);
    .title{
      margin-bottom: 10px;
    }
  }
  .price_detail_green{
    .num{
      color: #3BD367;
    }
    color: #333;
    font-weight: normal;
  }
  .price_detail{
    img{
      margin-left: 7px;
      width: 6px;
    }
    font-size:13px;
    font-weight:500;
    color:rgba(255,187,80,1);
  }
  .h_content{
    .price_out{
      .hod_hx{
        height:35px;
        border:1px solid rgba(32,193,119,1);
        border-radius:3px;
        line-height: 35px;
        font-size:13px;
        font-weight:500;
        color:rgba(32,193,119,1);
        margin-top: 15px;
      }
      .td_rule{
        .td_title{
          color: #3E3E3E;
        }
        margin-top: 24px;
        font-size:13px;
        font-weight:500;
        color:#A0A0A0;
      }
      .back_tip{
        font-size:13px;
        font-weight:500;
        color:rgba(160,160,160,1);
        margin: 8px 0 13px 0;
      }
      .order_num{
        margin-top: 13px;
      }
      .copy_btn{
        width:35px;
        height:14px;
        border:1px solid rgba(192,192,192,1);
        border-radius:7px;
        margin-left: 19px;
        font-size:10px;
        line-height: 14px;
        font-weight:400;
        color:rgba(62,62,62,1);
      }
      .num{
        font-size:13px;
        font-weight:500;
        color:rgba(62,62,62,1);
      }
      .title{
        width: 60px;
        margin-right: 8px;
        font-size:13px;
        font-weight:500;
        color:rgba(62,62,62,1);
      }
      .red{
        font-size:15px;
        font-weight:500;
        color:rgba(255,42,0,1);
        .red_black{
          font-size:13px;
          font-weight:500;
          color:rgba(62,62,62,1);
        }
      }
      margin-bottom: 10px;
    }
    .bc_w{
      background-color: white;
      padding: 13px 20px;
    }
    height: 100%;
    box-sizing: border-box;
    padding-top: 180px;
    background-color: #f0f0f0;
    &.tall{
      padding-top: 255px;
    }
  }
  .mt_15{
    margin-top: 15px;
  }
  .od_top{
    .top_content{
      position: relative;
      top: -1px;
      .refuse{
        height:35px;
        line-height: 35px;
        background:rgba(255,248,222,1);
        border:1px dotted rgba(255,172,65,1);
        border-radius:3px;
        padding: 0 10px;
        font-size:12px;
        font-weight:500;
        color:rgba(255,187,80,1);
      }
      .hod_right{
        position: relative;
        top: -15px;
        img{
          width: 7px;
          margin-left: 6px;
          position: relative;
          top: 2px;
        }
        font-size:13px;
        font-weight:bold;
        color:rgba(255,255,255,1);
      }
      .btn{
        &.full{
          width: 100%;
        }
        &.ac{
          background:rgba(255,187,80,1);
          color: white;
        }
        cursor: pointer;
        width:146px;
        height:35px;
        line-height: 35px;
        border:1px solid rgba(255,187,80,1);
        border-radius:3px;
        font-size:15px;
        font-weight:500;
        color:rgba(255,187,80,1);
      }
      .btn_out.two{
        .btn{
          width: 153px;
        }
      }
      .white_card{
        .head{
          .black{
            margin-left: 13px;
            color:#3E3E3E;
          }
          font-size:12px;
          font-weight:500;
          color:rgba(160,160,160,1);
          height: 40px;
          line-height: 40px;
          border-bottom: 1px solid #D0D0D0;
          margin-bottom: 17px;
        }
        margin-top: 13px;
        padding: 0 10px 18px 10px;
        background:rgba(255,255,255,1);
        border-radius:3px;
      }
      .cut_down{
        .van-count-down{
          font-size:17px;
          font-weight:bold;
          color:rgba(255,255,255,1);
          vertical-align: bottom;
        }
        font-size:17px;
        font-weight:bold;
        color:rgba(255,255,255,1);
      }
      .sub{
        font-size:13px;
        font-weight:500;
        color:rgba(255,255,255,1);
        margin-top: 14px;
      }
      .title{
        font-size:20px;
        font-weight:bold;
        color:rgba(255,255,255,1);
        .hod_right{
          top: 0;
        }
      }
      padding: 0 21px 15px 21px;
      background: linear-gradient(75deg, #7fdc4f, #1ddf50);
    }
    width: 100%;
    top: 0;
    left: 0;
    height: 180px;
    box-sizing: border-box;
  }
</style>
